import React, { lazy, Suspense } from 'react'
import {
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import history from '../history'

// prod
const AsyncCreateAccount = lazy(() => import('../pages/CreateAccount'));
const AsyncStripeConfig = lazy(() => import('../pages/stripeConfig'));
const AsyncGrabCommerce = lazy(() => import('../pages/GrabCommerce'));
const AsyncGrabpay = lazy(() => import('../pages/Grabpay'));
const AsyncLoading = lazy(() => import('../pages/loading'));

// test
const AsyncGrabCommerceTest = lazy(() => import('../pages/test/GrabCommerce'));
const AsyncGrabpayTest = lazy(() => import('../pages/test/Grabpay'));

// uat
const AsyncGrabCommerceUat = lazy(() => import('../pages/uat/GrabCommerce'));
const AsyncGrabpayUat = lazy(() => import('../pages/uat/Grabpay'));

const getRouter = () => (
  <Router history={history}>
    <div>
      <Suspense fallback={<div>loading....</div>}>
        <Switch>
          <Redirect exact path="/" to={{ pathname: '/signup' }} />
          <Route path="/signup" component={AsyncCreateAccount} />
          <Route path="/stripeConfig" component={AsyncStripeConfig} />
          <Route path="/GrabCommerce" component={AsyncGrabCommerce} />
          <Route path="/Grabpay" component={AsyncGrabpay} />
          <Route path="/loading" component={AsyncLoading} />

          <Route path="/GrabCommerce_test" component={AsyncGrabCommerceTest} />
          <Route path="/Grabpay_test" component={AsyncGrabpayTest} />

          <Route path="/GrabCommerceUAT" component={AsyncGrabCommerceUat} />
          <Route path="/GrabpayUAT" component={AsyncGrabpayUat} />
        </Switch>
      </Suspense>
    </div>
  </Router>
)
export default getRouter
